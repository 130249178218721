import { OutboundDocumentLineSchema, OutboundDocumentSchema } from '@nest/domain-entities-metadata-shared-zod';
import { z } from 'zod';

export const OutboundDocumentCreateLineDtoSchema = OutboundDocumentLineSchema.pick({
	item: true,
	quantity: true,
	text: true,
	code: true,
	position: true,
	price: true,
	recipientPriceAmount: true,
	vat: true,
	saleOrderLine: true,
	accountingCreditSide: true,
	accountingDebitSide: true
});

export const OutboundDocumentCreateDtoSchema = OutboundDocumentSchema.pick({
	warehouse: true,
	buyer: true,
	recipient: true,
	documentType: true,
	currency: true,
	recipientTotalPrice: true,
	correctionOriginalDocument: true,
	buyerNote: true,
	note: true,
	exchangeRate: true,
	calculateTax: true,
	recipientNote: true,
	documentKindMask: true,
	issuedDate: true,
	saleOrder: true,
	excludeFromStatistics: true,
	isDropshipping: true,
	carrier: true,
	deliveryType: true,
	paymentType: true,
	buyerOrderNumber: true,
	foreignOrderNumber: true,
	variableSymbol: true,
	deliveryShipmentTimeWindows: true,
	requestDate: true
}).extend({
	recipientSnapshot: z.any().optional(),
	lines: z.array(OutboundDocumentCreateLineDtoSchema)
});

export type OutboundDocumentCreateDto = z.infer<typeof OutboundDocumentCreateDtoSchema>;
export type OutboundDocumentCreateLineDto = z.infer<typeof OutboundDocumentCreateLineDtoSchema>;

export const OutboundDocumentUpdateLineDtoSchema = OutboundDocumentCreateLineDtoSchema.extend({
	id: z.number()
});

export const OutboundDocumentUpdateDtoSchema = OutboundDocumentCreateDtoSchema.extend({
	id: z.number(),
	lines: z.array(OutboundDocumentUpdateLineDtoSchema)
});

export type OutboundDocumentUpdateDto = z.infer<typeof OutboundDocumentUpdateDtoSchema>;
export type OutboundDocumentUpdateLineDto = z.infer<typeof OutboundDocumentUpdateLineDtoSchema>;
